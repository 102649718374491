import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import { Button, Collapse} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OffcanvasSettings from '../offcanvas/OffcanvasSettings';
import OffcanvasProfile from '../offcanvas/OffcanvasProfile';
import { FaListOl} from 'react-icons/fa6'
import { useSelector } from 'react-redux';
function WikiOffcanvas({open ,handleShow}) {
  // code for getting the user from redux
  const user = useSelector((state) => state.user.user);
  // hook for translation
  const { i18n, t } = useTranslation();
  const [openlist, setOpenlist] = useState(true);
  return (
    <>
     <style>
        {`
        .offcanvas-body-custom {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .offcanvas-footer {
          margin-top: auto;
        }
      `}
      </style>
      <Offcanvas show={open} onHide={handleShow} {...{placement:'end'}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='offcanvas-body-custom'>
        <Button variant='deafult' className='border-0 border-bottom border-secondary rounded-0 bg-transparent fw-bold container-fluid text-start mb-1' 
                    onClick={()=>{setOpenlist(!openlist)}}>
                      <FaListOl className='text-primary me-2 mb-1 fw-bold h4'/>
              {t('wikiHeaders.offcanvasTitle')}
            </Button>
            <Collapse in={openlist}>
              <div>
              <Nav className="d-block" >
              <div className='fs-5 ms-2 fw-bold border-0 border-bottom border-secondary rounded-0'>
              {t('wikiHeaders.general')}
              </div>
              
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki'>{t('wikiHeaders.home')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/bibliography'>{t('wikiHeaders.bibliography')}</Nav.Link>
              </Nav.Item>
                
              <div className='fs-5 ms-2 fw-bold border-0 border-bottom border-secondary rounded-0'>
              {t('wikiHeaders.indicators')}
              </div>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/ma'>{t('wikiHeaders.ma')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/bb'>{t('wikiHeaders.bb')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/rsi'>{t('wikiHeaders.rsi')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/macd'>{t('wikiHeaders.macd')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/sar'>{t('wikiHeaders.sar')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/sto'>{t('wikiHeaders.sto')}</Nav.Link>
              </Nav.Item>

              <div className='fs-5 ms-2 fw-bold border-0 border-bottom border-secondary rounded-0'>
              {t('wikiHeaders.strategies')}
              </div>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/bollinger_rsi'>{t('wikiHeaders.bollinger_rsi')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/moving_average_intersection'>{t('wikiHeaders.moving_average_intersection')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/macd_ema'>{t('wikiHeaders.macd_ema')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/sar_macd'>{t('wikiHeaders.sar_macd')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='mx-2 ' href='/wiki/stochastic_rsi'>{t('wikiHeaders.stochastic_rsi')}</Nav.Link>
              </Nav.Item>
              </Nav>
              </div>
            </Collapse>
            {user ?
            <div className="offcanvas-footer">
            <OffcanvasSettings />
            <OffcanvasProfile />
            </div>
            :
            <></>}

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default WikiOffcanvas;