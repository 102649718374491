import React, { useState, useEffect } from 'react';
import './Landing.css'

const Countdown = ({addClass}) => {
    // Define the final time as a Unix timestamp (seconds since epoch)
    const finalTime = Math.floor(new Date('2024-12-25T00:00:00Z').getTime() / 1000);

    const [timeLeft, setTimeLeft] = useState(null);

    useEffect(() => {
        // Calculate the initial remaining time
        const calculateTimeLeft = () => {
            const now = Math.floor(Date.now() / 1000); // Current time in seconds
            return Math.max(finalTime - now, 0); // Ensure non-negative time
        };

        setTimeLeft(calculateTimeLeft()); // Set the initial remaining time

        // Update the countdown every second
        const interval = setInterval(() => {
            setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0)); // Decrement time
        }, 1000);

        return () => clearInterval(interval); // Cleanup the interval on unmount
    }, [finalTime]);

    // Format time into days, hours, minutes, and seconds
    const formatTime = (seconds) => {
        const days = Math.floor(seconds / (3600 * 24));
        const hours = Math.floor((seconds % (3600 * 24)) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        // Ensure two digits for hours, minutes, and seconds
        const paddedHours = String(hours).padStart(2, '0');
        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedSeconds = String(secs).padStart(2, '0');

        return `${days}d ${paddedHours} : ${paddedMinutes} : ${paddedSeconds}`;
    };

    return (
        <div className="countdown-container" >
            <div className={"countdown-box "+ addClass}>
                {timeLeft !== null ? (
                    timeLeft > 0 ? (
                        <div >{formatTime(timeLeft)}</div>
                    ) : (
                        <div >00 : 00 : 00</div>
                    )
                ) : (
                    <div >Loading...</div>
                )}
            </div>
        </div>
    );
};

export default Countdown;