import React from 'react';
import './Landing.css';
import { Container, Row, Col} from 'react-bootstrap';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
const Comparison = () => {
  // hook for translation
  const { i18n, t } = useTranslation();

  // for configuring the slider we are using keenslider library
  const [currentSlide, setCurrentSlide] = useState(1)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: "free-snap",
    breakpoints: {
      '(min-width: 990px)': {
        slides: {
          perView: 2.3,
          spacing: 0,
        },
      },
    },
    slides: {
      origin: 0,
      perView: 1.05,
      spacing: 15,
    },
    initial: 1,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  });
 
  // this are the arrows 
  function Arrow(props) {
    const disabled = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabled} d-block d-lg-none`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
  }
  // check simbol
  const CheckSimbol = ({color})=>{
    return(
      <div>
      <svg focusable="false" fill={color} color ="#1a1a1a"fill-opacity="0.8" height="25px" viewBox="0 0 16 16" width="25px"><path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fill-rule="evenodd"></path></svg>
      </div>
    )
  }
  // x simbol
  const XSimbol = ({color})=>{
    return(
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill={color} class="bi bi-x-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
        </svg> 
      </div>
    )
  }
  return (
    <div className='black-background'>
      <Container className="padding-section">
        <Row className="text-center">
          <Col className="title text-white">
          {t(`landing.comparison.title`)}
          </Col>
        </Row>
        <Row className="mt-4 mt-lg-5 text-start navigation-wrapper">
          <div ref={sliderRef} className="keen-slider pt-2 pb-2">
            <Container className='comparison-without-box keen-slider__slide number-slide1 text-white px-2 px-lg-5'>
              <Row className="comparison-without-title">
                <Col>
                  {t(`landing.comparison.title_without`)}
                </Col>
              </Row>
              <Row className="comparison-without-text mt-3  d-flex flex-column justify-content-between h-100 pb-5">
                <div className='d-flex aling-items-center pt-1'><XSimbol color='white'></XSimbol> <div className='ms-3'>{t(`landing.comparison.text_without_1`)}</div></div>
                <div className='d-flex aling-items-center pt-1'><XSimbol color='white'></XSimbol> <div className='ms-3'>{t(`landing.comparison.text_without_2`)}</div></div>
                <div className='d-flex aling-items-center pt-1'><XSimbol color='white'></XSimbol> <div className='ms-3'>{t(`landing.comparison.text_without_3`)}</div></div>
                <div className='d-flex aling-items-center pt-1'><XSimbol color='white'></XSimbol> <div className='ms-3'>{t(`landing.comparison.text_without_4`)}</div></div>
                <div className='d-flex aling-items-center pt-1'><XSimbol color='white'></XSimbol> <div className='ms-3'>{t(`landing.comparison.text_without_5`)}</div></div>
              </Row>
            </Container>
            <Container className='comparison-with-box img-shadows keen-slider__slide number-slide2 text-black pt-4 pb-4 px-2 px-lg-5'>
              <Row className="comparison-without-title">
                <Col>
                  {t(`landing.comparison.title_with`)}
                </Col>
              </Row>
              <Row className="comparison-without-text mt-3 d-flex flex-column justify-content-between h-100 pb-5">
                <div className='d-flex aling-items-center pt-1'><CheckSimbol color='#EE7AD8'></CheckSimbol> <div className='ms-3'>{t(`landing.comparison.text_with_1`)}</div></div>
                <div className='d-flex aling-items-center pt-1'><CheckSimbol color='#EE7AD8'></CheckSimbol> <div className='ms-3'>{t(`landing.comparison.text_with_2`)}</div></div>
                <div className='d-flex aling-items-center pt-1'><CheckSimbol color='#EE7AD8'></CheckSimbol> <div className='ms-3'>{t(`landing.comparison.text_with_3`)}</div></div>
                <div className='d-flex aling-items-center pt-1'><CheckSimbol color='#EE7AD8'></CheckSimbol> <div className='ms-3'>{t(`landing.comparison.text_with_4`)}</div></div>
                <div className='d-flex aling-items-center pt-1'><CheckSimbol color='#EE7AD8'></CheckSimbol> <div className='ms-3'>{t(`landing.comparison.text_with_5`)}</div></div>
              </Row>
            </Container>
          </div>
          {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow

              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
        </Row>
      </Container>
    </div>
  );
};

export default Comparison;