import { BrowserRouter, Route, Routes} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import App from '../app/App';
import React from 'react';
import Wiki from '../app/Wiki';
import Ranking from '../app/Ranking';
import Aboutus from '../app/Aboutus';
import Landing from '../components/landing/Landing';
import {ComponentMarkdown } from '../components/wiki_components/WikiPages';
//import Landing from '../app/Landing';
import { Privacy, Terms } from '../app/policies/Policies';
import Pricing  from '../components/pricing/Pricing';
import ReloadUser from '../components/ReloadUser';

const Router = () => {

    return(
        <BrowserRouter>
            <Routes>   
                <Route index element={<Landing/>} />
                <Route path='todaysignals' element={<App/>} />
                <Route path='pricing' element={<Pricing/>} />
                <Route path='wiki' element={<Wiki/>} >
                    <Route index element={<ComponentMarkdown markdownContent='wiki.home'/>}/>
                    <Route path='home' element={<ComponentMarkdown markdownContent='wiki.home'/>}/>
                    <Route path='bb' element={<ComponentMarkdown markdownContent='wiki.bb'/>}/>
                    <Route path='macd' element={<ComponentMarkdown markdownContent='wiki.macd'/>}/>
                    <Route path='rsi' element={<ComponentMarkdown markdownContent='wiki.rsi'/>}/>
                    <Route path='sar' element={<ComponentMarkdown markdownContent='wiki.sar'/>}/>
                    <Route path='sto' element={<ComponentMarkdown markdownContent='wiki.sto'/>}/>
                    <Route path='ma' element={<ComponentMarkdown markdownContent='wiki.ma'/>}/>
                    <Route path='bollinger_rsi' element={<ComponentMarkdown markdownContent='wiki.bollinger_rsi'/>}/>
                    <Route path='moving_average_intersection' element={<ComponentMarkdown markdownContent='wiki.moving_average_intersection'/>}/>
                    <Route path='macd_ema' element={<ComponentMarkdown markdownContent='wiki.macd_ema'/>}/>
                    <Route path='sar_macd' element={<ComponentMarkdown markdownContent='wiki.sar_macd'/>}/>
                    <Route path='stochastic_rsi' element={<ComponentMarkdown markdownContent='wiki.stochastic_rsi'/>}/>
                    <Route path='bibliography' element={<ComponentMarkdown markdownContent='wiki.bibliography'/>}/>
                    <Route path="*" element={<div>404</div>} />
                </Route>
                <Route path='ranking' element={<Ranking/>} />
                <Route path='aboutus' element={<Aboutus/>} />
                <Route path='privacy-policy' element={<Privacy/>}/>
                <Route path='terms-and-conditions' element={<Terms/>}/>
                <Route path='ads.txt' element={<div>google.com, pub-4786909472395970, DIRECT, f08c47fec0942fa0</div>}/>
                <Route path='update-user' element={<ReloadUser/>}/>
                <Route path="*" element={<div>404</div> } />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;