import React from 'react';
import './Landing.css';
import {Accordion, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const Faq = () => {
    const {i18n, t} = useTranslation();
    // this controls the number of questions to load they must exist in the translationEN and ES files
    const num_questions = Array.from({ length: 5 }, (_, index) => index);
return (
<Container className='padding-section'>
    <div className='title text-center'>
        {t('landing.faq.title')}
    </div>
    <div className='text-under-button text-center mt-lg-4'>
        {t('landing.faq.subtitle')}
        <a href='mailto:hello@atomicstonks.com'>
            {t('landing.faq.subtitle_link')}
        </a>
    </div>
    <Accordion className='mt-3 mt-lg-5'>
        {num_questions.map((question_number) => (
             <Accordion.Item eventKey={question_number}>
             <Accordion.Header  >
                 <div className='accordion-header'>
                 {t('landing.faq.'+question_number+'.question')}
                 </div>
             </Accordion.Header>
             <Accordion.Body className='accordion-text'>
                {t('landing.faq.'+question_number+'.answer')}
             </Accordion.Body>
         </Accordion.Item>
        ))}
        </Accordion>
</Container>
);
};

export default Faq;