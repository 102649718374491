import React, { useState } from 'react';
import {initTheme} from '../../utils/theme'
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import './Landing.css';
import '../header/Header.css'
import Footer from '../Footer';
import LandingHeader from './LandingHeader';
import LastCall2Action from './LastCall2Action';
import Hero from './Hero';
import Faq from './Faq';
import Critique from './Critique';
import Solution from './Solution';
import Features from './Features';
import PricingLandingSection from './PricingLandingSection';
import TryIt from './TryIt';
import SocialProof from './SocialProof';
import HowItWorks from './HowItWorks';
import { useLocation } from 'react-router-dom';
import AlertBarDiscount from './AlertBarDiscount';
import Comparison from './Comparison';
import { landingArrays } from '../../utils/landingArrays';

const Landing = () => {
 // code for initializing the theme
  initTheme()
  // google analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  // function to select a random item from an array
  function getRandomItem(arr) {
    if (!Array.isArray(arr) || arr.length === 0) {
        throw new Error("Input must be a non-empty array.");
    }
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
}
  //this variable controls the current landing version meaning the code supported version of array
  const currentLandingVersion = 0;
  // array that controls the landing sections
  const [landingArray, setLandingArray] = useState(null);

  useEffect(() => {
    // we try to find it in the localstorage
    var la = JSON.parse(localStorage.getItem('landingArray'));
    // if not we create a new one
    if (la===null){
      createLandingArray()
    // here we control the landing versions this allows us to regenerate the landing array
    // and do try to parse an old one stored, if we introudce breaking changes to the old landing parsing
    }else if(la.landingVersion === currentLandingVersion ){
      setLandingArray(la.landingArray)
    }
    else{
      createLandingArray()
    }
    
  }, []);
  // this functions must return a new landing array
  const createLandingArray = ()=>{
    var array = getRandomItem(landingArrays)
    var result = {'landingVersion':currentLandingVersion,
                  'landingArrayVersion':array.version,
                  'landingArray':array.array};
    // we save the new variable in the local storage
    localStorage.setItem("landingArray", JSON.stringify(result));
    // we set the landing array to the created variable
    setLandingArray(result.landingArray);
  }
  

  //function to generate a component from an elemento of the landing array
  const GetLandingElement = ({e})=>{
    if(e.section==='hero'){
      return(    
      <div id={e.section}>
        <Hero option={e.option} joinButtonTextOption={e.cta}></Hero>
      </div>
      )
    }else if (e.section==='critique'){
      return(    
        <div id={e.section}>
          <Critique option={e.option}></Critique>
        </div>
        )
    }else if (e.section==='solution'){
      return(    
        <div id={e.section}>
          <Solution option={e.option} dark={e.dark}></Solution>
        </div>
        )
    }else if (e.section==='how_it_works'){
      return(    
        <div id={e.section}>
          <HowItWorks option={e.option} dark={e.dark}></HowItWorks>
        </div>
        )
    }else if (e.section==='try_it'){
      return(    
        <div id={e.section}>
          <TryIt option={e.option}></TryIt>
        </div>
        )
    }else if (e.section==='pricing'){
      return(    
        <div id={e.section}>
          <PricingLandingSection option={e.option}></PricingLandingSection>
        </div>
        )
    }else if (e.section==='faq'){
      return(    
        <div id={e.section}>
          <Faq option={e.option}></Faq>
        </div>
        )
    }else if (e.section==='social_proof'){
      return(    
        <div id={e.section}>
          <SocialProof option={e.option}></SocialProof>
        </div>
        )
    }else if (e.section==='features'){
      return (
        <div id={e.section}>
          <Features option={e.option} dark={e.dark}></Features>
        </div>
      )
    }else if (e.section==='last_call_to_action'){
      return(    
        <div id={e.section}>
          <LastCall2Action option={e.option}  joinButtonTextOption={e.cta}></LastCall2Action>
        </div>
        )
    }else if (e.section==='comparison'){
      return(    
        <div id={e.section}>
          <Comparison option={e.option}></Comparison>
        </div>
        )
    }else{
      // if we are parsing an array wich has a not contemplated option that means is an old landing array
      // currently not supported because we have changed it, so what we do is to regenerate the whole landing array
      // if we correctly use the landing version this should not be used
      createLandingArray();
    }
  }
  
    return (
    <div>
      {landingArray &&
        <div >
          <LandingHeader  joinButtonTextOption={landingArray[0].joinButtonTextOption}></LandingHeader>
          <AlertBarDiscount></AlertBarDiscount>
          {landingArray.map((e)=>{
            return(
              <GetLandingElement e ={e} ></GetLandingElement>
            )
          })}
          <Footer className="mt-5"></Footer>
        </div>
      
      } 
    </div>
    );
  };

export default Landing;