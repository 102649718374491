import React from 'react';
import './Landing.css';
import { Container, Row, Col} from 'react-bootstrap';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
const SocialProof = () => {
  // hook for translation
  const { i18n, t } = useTranslation();

  // for configuring the slider we are using keenslider library
  const [currentSlide, setCurrentSlide] = useState(1)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: "free-snap",
    breakpoints: {
      '(min-width: 990px)': {
        slides: {
          origin: "center",
          perView: 2.5,
          spacing: 40,
        },
      },
      '(max-width: 380px)': {
        slides: {
          origin:0,
          perView: 1.05,
          spacing: 40,
        },
    },
  },
    slides: {
      origin:0,
      perView: 1.05,
      spacing: 40,
    },
    initial: 1,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  });
  // this controls the number of reviews to load they must exist in the translationEN and ES files
  const num_reviews = Array.from({ length: 6 }, (_, index) => index);
  // this has the urls for the people photo must be in same order as reviews
  const reviewers_photos = [
    "https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/customer_reviews/victor.png",
    "https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/customer_reviews/david.png",
    "https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/customer_reviews/parramon.png",
    "https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/customer_reviews/andrew.png",
    "https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/customer_reviews/eduardo.png",
    "https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/customer_reviews/alba.png",
    
  ];
  // this are the arrows 
  function Arrow(props) {
    const disabled = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabled}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
  }
  return (
    <div>
      <Container className="padding-section">
        <Row className="text-center">
          <Col className="title">
          {t(`landing.social_proof.title`)}
          </Col>
        </Row>
        <Row className="mt-4 mt-lg-5 text-start text navigation-wrapper">
          <div ref={sliderRef} className="keen-slider pt-2 pb-2">
            {num_reviews.map((slide_number) => (
            <Container
            className={`d-flex flex-column justify-content-between pt-4 pb-3 px-5 text-black bg-white img-shadows keen-slider__slide number-slide${
              slide_number + 1
            }`}
          >
            {/* Quote section */}
            <Row className="social-proof-quote text-center mt-auto mb-auto">
              {t(`landing.social_proof.${slide_number}.quote`)}
            </Row>
          
            {/* Bottom-aligned content */}
            <Row className="d-flex align-items-end">
              <div className="d-flex align-items-center">
                <img
                  className="img-social-proof-user-photo m-1 p-0"
                  src={reviewers_photos[slide_number]}
                  alt="User Photo"
                />
                <div className="ms-3 text-center">
                  <div className="social-proof-name">
                    {t(`landing.social_proof.${slide_number}.name`)}
                  </div>
                  <div
                    className={`mt-1 px-2 social-proof-badge text-center text-white rounded-2 ${
                      (t(`landing.social_proof.${slide_number}.user_type`) === "Pro User" || t(`landing.social_proof.${slide_number}.user_type`) === "Usuario Pro") ? "bg-primary" : "bg-secondary"
                    }`}
                  >
                    {t(`landing.social_proof.${slide_number}.user_type`)}
                  </div>
                </div>
              </div>
            </Row>
          </Container>
            ))}
          </div>
          {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
        </Row>
        {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            )
          })}
        </div>
      )}
      </Container>
    </div>
  );
};

export default SocialProof;