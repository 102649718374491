export const landingEn = {
    // This ones are the sections without options
    offcanvas:{
        pricing:'Pricing',
        faq:'FAQ',
        why_us:'Why Us?',
        how_it_works:'How It Works',
        about_us:'About Us'
    },
    alert_bar_discount:{
        text:'Limited time XMas Discount: ',
        link:'DEAL ↓'
    },
    faq:{
        title:'Frequently Asked Questions',
        subtitle:'Have another question? Contact us by ',
        subtitle_link:'email.',
        0:{
            question:'What is AtomicStonks? ',
            answer:'AtomicStonks is a powerful stock market analysis platform designed to streamline your investment process. \n\nIt provides quick market analysis with 5 different strategies to over 1700 companies of 30 of the main indexes of the world, even some emerging markets to provide you with relevant data and opportunities to diversify your risk.'
        },
        1:{
            question:'How does AtomicStonks differ from traditional investment methods?',
            answer:'Unlike traditional methods, AtomicStonks automates many time-consuming tasks, such as analyzing charts and screening stocks. It also allows you to test multiple investment strategies simultaneously, which can significantly improve your investment decision-making. \n\nAdditionally, our platform provides access to a diverse range of stocks, including emerging markets, that although might be in traditional channels, that, although they might be in traditional channels, many people might not even consider them from the start. '
        },
        2:{
            question:'Is AtomicStonks suitable for both beginner and experienced investors?',
            answer:'Absolutely! Our platform is designed to be user-friendly for investors of all levels. Whether you are a seasoned investor or just starting, AtomicStonks can help you make informed investment decisions and achieve your financial goals. \n\nWe obviously recommend checking out some of the books that we mention in the bibliography in the wiki, to have some notions of the basics of technical analysis. It is not absolutely mandatory but will probably improve your returns substantially.' 
        },
        3:{
            question:'What kind of data and analytics does AtomicStonks provide?',
            answer:'Our platform offers a wide range of data and analytics, including market data, historical price charts, fundamental analysis, technical indicators, and more. You can use this information to identify trends, assess risk, and make informed investment decisions.' 
        },
        4:{
            question:'How often is the data on AtomicStonks updated?',
            answer:'Every time a market closes we run our algorithms again to check if any new signals are provided.' 
        },
        5:{
            question:'Why these strategies and not others?',
            answer:'There is no one-size-fits-all answer to this question, as the best trading strategies will vary depending on your individual risk tolerance, investment goals, and market conditions. \n\nWe focus on these strategies because they are well-established and have a proven track record of success. We have left a long list of bibliography in the wiki to check out different books that talk about these strategies. We are consider to bringing  more to the table, so would love to have some feedback and recommendations!' 
        },
        6:{
            question:'Why only 7, 30, 90 and 180 days back in BackTesting?',
            answer:'There are a few reasons why AtomicStonks limit backtesting to 7, 30, 90, and 180 days. The main one is data relevance. We are very strict about this since it is one of our core values.\n\n We could have a list of how each signal has performed daily since the last 5 years without much trouble, however the digestion of that information would be atrocious for you. When there is more and more information it is more difficult for anyone to understand what is going on.The other main reason is that we consider these strategies are usually short mid term trades. There is no reason to go back in time that much more to feel the performance or the trend the markets have. That is why the section with these time frames made the most sense for us. However, if you don’t agree please let us know, we try hard to understand the customer’s needs and opinions.' 
        },
        7:{
            question:'Is my data safe and secure on AtomicStonks?',
            answer:'Yes, we take data security very seriously. That’s  why we use Google Sign-In to minimize handling personal information. \n\nWe also employ advanced security measures to protect the little personal information we might need to work with.' 
        },
    },
    social_proof:{
        title:'Why our customers love us?',
        0:{
            name:'Victor G',
            quote: '“AtomicStonks has helped me save time doing my due diligence, I use it daily.”',
            user_type:'Pro User'
        },
        1:{
            name:'David G',
            quote:'“The daily feed of signals helped me find opportunities in markets I wasn’t even thinking of.”',
            user_type:'Premium User'
        },
        2:{
            name:'Sophie P',
            quote: '“Wanted to start investing and this has helped me a lot. I felt like it was too much of a hassle and now is just a few minutes.”',
            user_type:'Pro User'
        },
        3:{
            name:'Andreu S',
            quote:'“Super useful tool for staying on top of the market without drowning in data.“',
            user_type:'Premium User'
        },
        4:{
            name:'Edu A',
            quote: '“AtomicStonks, is a game-changer for anyone in the investing world! Specifically the ones who want to be efficient but assertive when hand-picking.”',
            user_type:'Pro User'
        },
        5:{
            name:'Alba B',
            quote:'“AtomicStonks is incredible! The detailed daily signals could be exactly what many investors need to stay ahead.”',
            user_type:'Premium User'
        }
    },
    comparison:{
        title:'How many hours do you spend looking at charts?',
        title_without:'Without AtomicStonks',
        text_without_1:'Hours analyzing charts',
        text_without_2:'Checking one strategy at a time',
        text_without_3:'Go through the same companies',
        text_without_4:'Never discover new markets',
        text_without_5:'If no investment is found, redo everything the next da',
        title_with:'With AtomicStonks',
        text_with_1:'Minutes to analyze the whole market',
        text_with_2:'Checking 5 different strategies at the same time',
        text_with_3:'Going through companies you might never heard of',
        text_with_4:'Discover new and emerging markets',
        text_with_5:'If no investment is found, come back the next day!',

    },
    try_it:{
        title:'Try it yourself!',
        text_up_signal:'In this example we have a Long signal of the strategy Bollinger + RSI \n indicating it might be a good idea to buy Disney.',
        text_down_signal:'Use the buttons to make a quick analysis of the company!',
    },    
    pricing:{
        title:'Save hours. Explore new markets.',
        subtitle:"Don't miss the Xmas deal! Get 50% off your subscription for life"
    },
    // from here down are the sections wich have options
    // an options is a number more
    hero:{
        0:{
            title:'Automated signals of investment opportunities.',
            subtitle:'Be on the top of the market without spending days analyzing charts',
            underButtonText:"Don't miss the limited-time offer",
            socialProofText:'+10 000 investors are already saving time'
        },
        1:{
            title:'Automated investment signals',
            subtitle:'AtomicStonks helps you make better investing decisions. Our advanced algorithm analyzes vast amounts of market data from more than 1700 companies, to identify trends and find great investment opportunities.',
            underButtonText:'Want to try it for free?',
            socialProofText:'+10 000 investors are already saving time'
        },
        2:{
            title:'Today’s most interesting stocks',
            subtitle:'Each day, AtomicStonks analyzes the prices of over 1,700 companies, delivering a curated list of the most relevant companies to consider.',
            underButtonText:'Want to try it for free?',
            socialProofText:'+10 000 investors are already saving time'
        },
        3:{
            title:'Here is the list of today’s most interesting stocks',
            subtitle:'Each day, AtomicStonks analyzes the prices of over 1,700 companies, delivering a curated list of the most relevant companies to consider.',
            underButtonText:'Want to try it for free?',
            socialProofText:'+10 000 investors are already saving time'
        },
    },
    solution:{
        0:{
            title_1:'Your ',
            title_enfasis:'automatic',
            title_2:' analysis tool of the global stock market',
            subtitle:'AtomicStonks monitor & analyzes over 1700 companies from main indexes all above the globe to create a daily feed of signals of potential investments.',
            bullet_1:'30 indexes of the most important global economies.',
            bullet_2:'5 different strategies to find your new investment.',
            bullet_3:'Fundamentals data to deepen analysis.',
        },
        1:{
            title_1:'Investing can be ',
            title_enfasis:' overwhelming ',
            title_2:'...',
            subtitle:'...You spend many hours sifting through graphs, fundamentals, and figuring out the perfect strategy. \n AtomicStonks provides an automated daily feed of signals to find the perfect investment opportunity for you.',
            bullet_1:'30 indexes of the most important global economies.',
            bullet_2:'5 different strategies to find your new investment.',
            bullet_3:'Fundamentals data to deepen analysis.',
        },
        2:{
            title_1:'How are you choosing where you put your',
            title_enfasis:' money ',
            title_2:'?',
            subtitle:'AtomicStonks monitor & analyzes over 1700 companies from main indexes all above the globe to create a daily feed of signals of potential investments.',
            bullet_1:'30 indexes of the most important global economies.',
            bullet_2:'5 different strategies to find your new investment.',
            bullet_3:'Fundamentals data to deepen analysis.',
        },
    },
    critique:{
        0:{
            title_1:'There are over 50K listed companies in the world',
            text_1:' Are you still investing in the same companies over and over again?\n Maybe your next opportunity is elsewhere.',
            title_2:'How are you choosing where you put your money?',
            text_2:'Because the company is famous? Solid.\n Your cousin put money there? Duh.\nYou checked the fundamentals? Yeah sure.',
            title_3:'We got it, it can be overwhelming',
            text_3:'That’s why we made AtomicStonks, to help you save hours of analyzing and find better opportunities!'
        },
        1:{
            title_1:'There are over 50K listed companies in the world',
            text_1:'You are trying to find the right one to invest with a graph, analyzing one at a time.',
            title_2:'How are you choosing where to invest?',
            text_2:'Looking for trends? \n Listening to the advice of friends or influencers?',
            title_3:'We got it, it can be overwhelming',
            text_3:'That’s why we made AtomicStonks, to help you save hours and find better opportunities!',
        },
        2:{
            title_1:'There are over 50K listed companies in the world',
            text_1:' Are you still investing in the same companies over and over again? Maybe your next opportunity is elsewhere.',
            title_2:'We got it, it can be overwhelming',
            text_2:'That’s why we made AtomicStonks, to help you save hours of analyzing and find better opportunities!',
        },
    },
    how_it_works:{
        0:{
            title:'How it works',
            text:'AtomicStonks does the heavy lifting. It creates a list of signals generated with different investment strategies. You just need to:',
            bullet_1:'Find a signal that matches your risk profile.',
            bullet_2:'Analyze why the strategy triggered a signal for that stock.',
            bullet_3:'Access the company fundamentals for deeper insights.',
            bullet_4:'Decide wether or not it is a fit for you!',
            bullet_5:'Repeat!',
        },
        1:{
            title:'How it works',
            text:'Each day you will find a list with all the signals triggered, the most relevant companies of the day.\nAtomicStonks does the heavy lifting, you just need to choose the best investment opportunity for you. ',
            bullet_1:'Access the company fundamentals for deeper insights.',
            bullet_2:'Analyze why the strategy triggered a signal...',
            bullet_3:'Check the Backtesting section to see how the signals have performed in the past.',
        },
    },

    features:{
        0:{
            title_1:'Analyze the market in minutes',
            text_1:'Get a daily feed of signals from over 1700 companies and 30 indexes',
            title_2:'Discover new opportunities',
            text_2:'Access a vast scope of stocks, including those flying under the radar of traditional investment analysis',
            title_3:'Test multiple strategies',
            text_3:'Simultaneously test 5 different investment strategies, and identify high-potential opportunities.',
        },
    },


    last_call_to_action:{
        0:{
            title:'Boost your decisions in the stock market.\nStart winning.',
            subtitle:'Don’t waste more time analyzing charts.'
        },
    },
    joinButton:{
        0:'Join Us!',
        1:'Get Started',
        2: 'See Now!'
    },
    login:'Log In', 
    title:'Invest smarter',
    title2:' with AtomicStonks',
    subtitle6:'Stop wasting time ',
    subtitle61:'analyzing countless charts hoping to find the perfect investment opportunity. Our tool automatically calculates the signals, ',
    subtitle62: 'you choose ',
    subtitle63: 'and deepen your analysis.',
    subtitle1:'We analyze over 1700 companies daily, creating a daily feed of short and long entry signals for 5 of the main technical strategies.',
    subtitle3:'Stop wasting time',
    subtitle31:' scanning through countless graphs.',
	subtitle4:'Focus on choosing',
    subtitle41:' the perfect opportunity.',
	subtitle5:'Deepen your analysis',
    subtitle51:' with our tools.',
    howitworks1:'AtomicStonks calculates the signals, ',
    howitworks2:'you choose',
    signalExpIntro1:'The ',
    signalExpIntro2:' section shows you all the signals triggered today, so you can focus on choosing the right one.',
    signalExpIntro3:'Here we have a ',
    signalExpIntro4:' signal of the strategy ',
    signalExpIntro5:' indicating it could be a good idea to buy Disney.',
    signalExp1:'Understand and study each company easily to see if it’s a fit for you.',
    signalExp1_1:'Access the company information with ',
    signalExp2:'and the graph with ',
    signalExp3:'Complete your analysis with TradingView using ',
    signalExp4:'That simple.',
    backTestingTitle1:'Process all the information ',
    backTestingTitle2:'Do the signals work?',
    backTesting1:'The ',
    backTesting2:' section will show you how the signals shown days ago have performed, by following the strategy.',
    signalButton:'Join us!',
    features1:'+ 1700 Companies',
    features11:'Every day we check if the strategies are fulfilled for more than 1700 companies from all over the world, providing you the signals.',
    features2:'Transparency, our priority',
    features22:'The ',
    features23:' section contains all the information about the indicators and strategies we use, so anyone can review our information.',
    features3:'30 indexes of 5 continents',
    features33:'We analyze 30 indexes from 28 different countries to gain global market and economic insights. All are major indexes like the S&P 500, NASDAQ 100, DAX 30, CAC 40, IBEX 35, and Nikkei 225, among many others.',
    video:'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/landingEN.mp4'
}