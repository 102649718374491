import { useTranslation } from 'react-i18next';
import './Landing.css'
import {useState } from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import Countdown from './Countdown';
const AlertBarDiscount = ({line, annotations, showInfo, setShowInfo}) => {
    const { i18n, t } = useTranslation();
    const [show, setShow] = useState(true);
return(
    <> 
        {show &&
            <div className="alert-bar-discount d-flex align-items-center text-center pt-2 pb-2">
                <div className='ms-auto me-2'>
                    {t('landing.alert_bar_discount.text')}
                    <a  href={`#${'pricing'}`}
                        onClick={() => {
                        document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' });
                        }}
                        className='text-decoration-none'
                    >
                    {t('landing.alert_bar_discount.link')}
                    </a>
                </div>
                <Countdown addClass={'countdown-alert-bar'}></Countdown>
                <CloseButton className="border-0 ms-3 ms-lg-auto me-3" onClick={()=>(setShow(false))}></CloseButton>
                
            </div>
        } 
    </>
  );

};

export default AlertBarDiscount;