import React from 'react';
import './Landing.css';
import JoinButton from './JoinButton';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const Hero = ({option, joinButtonTextOption}) => {
  // hook for translation
  const { i18n, t } = useTranslation();
return (
<Container className='padding-section mt-5 mt-lg-0'>
  <Row className='align-items-center'>
    <Col xs={7} xl={6} className='text-start'>
      <div className='title-big'>
        {t('landing.hero.'+option+'.title')}
      </div>
      <div className='subtitle text-start mt-4 mb-4'>
        {t('landing.hero.'+option+'.subtitle')}
      </div>
      <JoinButton addClass={'joinButton-pink text-black'} joinButtonTextOption={joinButtonTextOption}></JoinButton>
      <div className='text-under-button text-start'>
        {t('landing.hero.'+option+'.underButtonText')}
      </div>
      <Row className='d-none d-xl-flex pt-5 align-items-center'>
        <Col xs='auto' className='text-start'>
          <img height={'77px'} loading='lazy' src = 'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/landing_socialproof_all_people.png'></img>
        </Col>
        <Col className='text-center align-items-center '>
          <Row className='social-proof-text social-proof-text-section'>
            {t('landing.hero.'+option+'.socialProofText')}
          </Row>
          <Row className='social-proof-text-section'>
            <img  loading='lazy' src = 'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/Stars.png'></img>
          </Row>
        </Col>
      </Row>
    </Col>
    <Col xs={5} xl={6} className='d-flex p-0'>

      <img className= 'img-shadows img-phone-hero' src='https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/image_phone.png'></img>
      <img className= 'img-shadows d-none d-xl-block img-big-hero align-self-center' src='https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/image_full.png'></img>

    </Col>
  </Row>
  <Row className='d-flex d-xl-none pt-5 align-items-end'>
    <Col className='text-end'>
      <img height={'53px'} loading='lazy' src = 'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/landing_socialproof_all_people.png'></img>
    </Col>
    <Col className='text-center align-items-center '>
      <Row className='social-proof-text social-proof-text-section'>
        {t('landing.hero.'+option+'.socialProofText')}
      </Row>
      <Row className='social-proof-text-section'>
        <img  loading='lazy' src = 'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/Stars.png'></img>
      </Row>
    </Col>
  </Row>
</Container>
);
};

export default Hero;