import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faInstagram,
    faTiktok,
    faXTwitter,
    faLinkedin
  } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    // hook for translation
    const { i18n, t } = useTranslation();

return (
  <footer className="bg-dark text-light">
    <Container className="p-2 pt-5 pb-5">
      <Row>
        <Col lg = {4} xs= {12} className="text-center text-lg-start">
          <img src={'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/white_logo.svg'} width="163" height='auto' alt="" loading="lazy" />
          <div className="mt-4 mb-4">{t('footer.description')}</div>
          <Row className='mb-4'>
            <Col className="align-middle">
            <a
               className="text-white m-3"
               style={{width:"50px",height:"50px", paddingLeft:'6px', paddingTop:'8px'}}
               href="https://www.youtube.com/channel/UCY7yzz4qjvsLG6W8ml5hLdQ"
               target="_blank"
               rel="noreferrer"
               role="button"
               >
                <FontAwesomeIcon size='2xl' icon = {faYoutube}></FontAwesomeIcon></a>

            <a
               className="text-white m-3"
               style={{width:"50px",height:"50px" }}
               href="https://twitter.com/atomic_stonks"
               target="_blank"
               rel="noreferrer"
               role="button"
               ><FontAwesomeIcon size='2xl' icon = {faXTwitter}></FontAwesomeIcon></a>


            <a
               className="text-white m-3"
               style={{width:"50px",height:"50px" }}
               href="https://www.instagram.com/atomicstonks"
               target="_blank"
               rel="noreferrer"
               role="button"
               ><FontAwesomeIcon size='2xl' icon = {faInstagram}></FontAwesomeIcon></a>
            <a
               className="text-white m-3"
               style={{width:"50px",height:"50px" }}
               href="https://www.tiktok.com/@atomicstonks"
               target="_blank"
               rel="noreferrer"
               role="button"
               ><FontAwesomeIcon size='2xl' icon = {faTiktok}></FontAwesomeIcon></a>
            <a
               className="text-white m-3"
               style={{width:"50px",height:"50px" }}
               href="https://www.linkedin.com/company/atomicstonks/"
               target="_blank"
               rel="noreferrer"
               role="button"
               ><FontAwesomeIcon size='2xl' icon = {faLinkedin}></FontAwesomeIcon></a>
               </Col> 
            </Row>
            <div>
              <p>© 2024 Copyright:&nbsp;
              <a href="/">atomicstonks.com</a></p>
            </div>
        </Col>
        <Col lg = {4} xs= {12} className="px-4 mt-4 mt-lg-0">
          <Row>
            <Col xs={6}>
              <div className='fw-bold'>
                {t('footer.product')}
              </div>
              <div className='mt-2'>
                <a  href={'/pricing'} className='text-white text-decoration-none'>
                  {t('landing.offcanvas.pricing')}
                </a>
              </div>
              <div className='mt-2'>
                <a  href={'/#faq'} className='text-white text-decoration-none'>
                  {t('landing.offcanvas.faq')}
                </a>
              </div>
              <div className='mt-2'>
                <a  href={'/#social_proof'} className='text-white text-decoration-none'>
                  {t('landing.offcanvas.why_us')}
                </a>
              </div>
              <div className='mt-2'>
                <a  href={'/#how_it_works'} className='text-white text-decoration-none'>
                  {t('landing.offcanvas.how_it_works')}
                </a>
              </div>
              <div className='mt-2'>
                <a  href={'/aboutus'} className='text-white text-decoration-none'>
                  {t('landing.offcanvas.about_us')}
                </a>
              </div>
            </Col>
            <Col xs={6}>
              <div className='fw-bold'>
                {t('footer.legal')}
              </div>
              <div className='mt-2'>
                <a  href={'/privacy-policy'} className='text-white text-decoration-none'>
                  {t('footer.privacy_policy')}
                </a>
              </div>
              <div className='mt-2'>
                <a  href={'/terms-and-conditions'} className='text-white text-decoration-none'>
                  {t('footer.terms_and_conditions')}
                </a>
              </div>
            </Col>
          </Row>
            
        </Col>
        <Col lg = {4} xs= {12} className="mt-5">
        </Col>

      </Row>

    </Container>
  </footer>



  );

};

export default Footer;
