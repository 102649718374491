import React from 'react';
import './Landing.css';
import { Container, Row, Col } from 'react-bootstrap';
import { TbFileSearch } from "react-icons/tb";
import { BiWorld } from "react-icons/bi";
import { GoGraph } from "react-icons/go";
import { useTranslation } from 'react-i18next';
const Features = ({ option, dark }) => {
  const { t } = useTranslation();

  return (
    <div className={dark ? 'black-background text-white' : 'white-background text-black'}>
      <Container className='padding-section'>
        <Row className='align-items-center text-start'>
          <Col xs={12} lg={4}>
            <div className='d-flex flex-row align-items-center'>
              <TbFileSearch className={dark ? 'features-icon-black': 'features-icon-white'}/>
              <div className='title-features ms-2'>
                {t('landing.features.' + option + '.title_1')}
              </div>
            </div>
            <div className='text mt-4 mb-5 ms-2'>
              {t('landing.features.' + option + '.text_1')}
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className='d-flex flex-row align-items-center'>
              <BiWorld className={dark ? 'features-icon-black': 'features-icon-white'} />
              <div className='title-features ms-2'>
                {t('landing.features.' + option + '.title_2')}
              </div>
            </div>
            <div className='text mt-4 mb-5 ms-2'>
              {t('landing.features.' + option + '.text_2')}
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className='d-flex flex-row align-items-center'>
              <GoGraph className={dark ? 'features-icon-black': 'features-icon-white'} />
              <div className='title-features ms-2'>
                {t('landing.features.' + option + '.title_3')}
              </div>
            </div>
            <div className='text mt-4 mb-4 ms-2'>
              {t('landing.features.' + option + '.text_3')}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Features;