import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { initTheme } from '../utils/theme';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/userReducer';
import { setSettings } from '../redux/settingsReducer';
import axios from 'axios';
import { host } from '../utils/variablesProdTest';
import { useNavigate } from 'react-router-dom';

import './landing/Landing.css';
import Header from './header/Header';
import Footer from './Footer';

import { getCookieValue } from '../utils/getCookieValue';

const ReloadUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const settings = useSelector((state) => state.settings.settings);
  const user = useSelector((state) => state.user?.user || state.user);

  const [userUpdateComplete, setUserUpdateComplete] = useState(false);
  const [userTransaction, setUserTransaction] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false); // State to track image load that sends the data to shareAsale

  // get the id of the click it's not directly the affiliated id but it is what we got
// Obtén el valor de la cookie 'sas_m_awin'
const sas_m_awin = getCookieValue('sas_m_awin');

// Comprueba si 'sas_m_awin' no es null y si contiene la clave 'clickId'
const clickId = sas_m_awin && JSON.parse(sas_m_awin).clickId ? JSON.parse(sas_m_awin).clickId : null;

  // Initialize theme on component mount
  useEffect(() => {
    initTheme();
  }, []);

  // Update user if user information is present
  useEffect(() => {
    if (!user || !user.email) {
      navigate('/');
    } else {
      retryUpdateUser(user, 5); // Retry 5 times
    }
  }, [user, navigate]);

  // Retry mechanism for updating the user
  const retryUpdateUser = async (user, attempts) => {
    try {
      const response = await updateUser(user);
      if (response) {
        const transaction = await getUserTransaction(response);
        const _ = await updateUserWithAffiliate(user, clickId);
        setUserTransaction(transaction); // Get the transaction information with updated user
        setUserUpdateComplete(true);
      }
    } catch (error) {
      if (attempts > 0) {
        console.log(`Retrying... Attempts left: ${attempts - 1}`);
        retryUpdateUser(user, attempts - 1); // Retry with decremented attempts
      } else {
        console.log('Failed to update user. Please try again later.');
      }
    }
  };

    // Function to update the affiliate
    const updateUserWithAffiliate = async (user, affiliated) => {
      try {
        const response = await axios.post(host + 'updateUserWithAffiliate.php', {
          sub: user.sub,
          stats: {'affiliate_clickId' : affiliated},
        });
        return "Done"
  
      } catch (error) {
        handleError(error);
        throw error; // Rethrow the error for retry logic
      }
    };

  // Function to update user
  const updateUser = async (user) => {
    try {
      const response = await axios.post(host + 'updateUser.php', {
        email: user.email,
        name: user.name,
        last_name: user.last_name,
        info: user.info,
        sub: user.sub,
        settings: user.settings,
      });

      const updatedUser = { ...user, ...response.data };

      // Check for real changes before dispatching
      if (JSON.stringify(user) !== JSON.stringify(updatedUser)) {
        dispatch(setUser(updatedUser));
      }

      return updatedUser; // Return updated user

    } catch (error) {
      handleError(error);
      throw error; // Rethrow the error for retry logic
    }
  };

  // Function to get user transaction
  const getUserTransaction = async (user) => {
    try {
      const response = await axios.post(host + 'getUserTransaction.php', {
        stripe_id: user.stripe_id,
      });
      return response.data[0];
    } catch (error) {
      handleError(error);
      return null; // Return null or appropriate default to prevent undefined
    }
  };

  // Error handling function
  const handleError = (error) => {
    if (error.response) {
      console.error('Server Error:', error.response.data);
    } else if (error.request) {
      console.error('No Response:', error.request);
    } else {
      console.error('Error:', error.message);
    }
    console.error('Error Config:', error.config);
  };

  // Redirect to another page once the user update is complete and image is loaded
  useEffect(() => {
    if (userUpdateComplete && user && imageLoaded) {
      navigate('/todaysignals');
    }
  }, [userUpdateComplete, user, imageLoaded, navigate]);

  // Handle image load
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div>
      <Header handleShow={0} />
      <Container className="text-center pt-5 pb-5 mt-5 mb-5">
        <div className="mt-5 mb-5 pt-5 pb-5 d-none d-lg-block"></div>
        <div className="title mt-5 mb-5 pt-5 pb-5">
          <span>{t('update_user')}</span>
        </div>
        <div className="mt-5 mb-5 pt-5 pb-5 d-none d-lg-block"></div>        
        <div className="mt-5 mb-5 pt-5 pb-5 d-none d-lg-block"></div>
      </Container>
      <Footer />
      {userTransaction &&
        <img
          src={`https://www.shareasale.com/sale.cfm?tracking=${encodeURIComponent(userTransaction.stripe_id + '_' + userTransaction.created_at)}&amount=${userTransaction.total_excluding_tax}&merchantID=158080&transtype=sale`}
          width="1"
          height="1"
          onLoad={handleImageLoad} // Handle successful image load
        />
      } 
    </div>
  );
};

export default ReloadUser;