import React from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState, useEffect} from 'react';
import { pricingData, countryToCurrencyMap } from '../../utils/pricingData';
import SignInModal from '../landing/SingInModal';
import './Pricing.css'
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
const PricingTables = () => {
  // modal for login Button
  const [show, setShow] = useState(false);
  const [modalUrl, setModalUrl] = useState('/todaysignals');
  const  [send2stripe, setSend2stripe] = useState(false);
  const handleClose = () => setShow(false);
  const buttonClicked = (buttonClicked, send2stripe) =>{
    setSend2stripe(send2stripe);
    setModalUrl(buttonClicked);
    setShow(true);
  }
  // hook for translation
  const { i18n, t } = useTranslation();
  // code for getting the currency to show the user
  const getCurrencyFromLocale = (locale) => {
    const country = locale.split('-')[1];
    return countryToCurrencyMap[country] || 'EUR';
  };
  const formatPrice = (price, locale, currency) => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(price);
  };
  
  const [currency, setCurrency] = useState('USD'); // Default currency
  const [locale, setLocale] = useState('en-EN'); // Default locale, can be changed based on user settings

  useEffect(() => {
    const userLocale = navigator.language || navigator.userLanguage;
    const detectedCurrency = getCurrencyFromLocale(userLocale);
    setLocale(userLocale);
    setCurrency(detectedCurrency);
  }, []);
  
  // showwing monthly or yearly prices
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  // for configuring the slider we are using keenslider library
  const [currentSlide, setCurrentSlide] = useState(1)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: "free-snap",
    breakpoints: {
      '(min-width: 1200px)': {
        slides: {
          perView: 3.1,
          spacing: 0,
        },
      },
    },
    slides: {
      origin: 0.0035,
      perView: 1.05,
      spacing: 15,
    },
    initial: 1,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  });
 
  // this are the arrows 
  function Arrow(props) {
    const disabled = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabled} d-block d-lg-none`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
  }
  // Pricing element
  const PricingElement = (element)=>{
    const price=pricingData[element].currencies[currency][billingPeriod];
    const discount = {premium:0.8, pro:0.7}
    return(
      <Col xs='12' lg='4' className='keen-slider__slide number-slide2  p-1 p-lg-3' >
        <Card  className={'me-2 img-shadows p-4 rounded-4  plan h-100'}>
          <div style={{height:'55px'}}>
            {element==='premium' &&
              <Badge className='m-2 mt-0'>{t('pricing.premium_badge')}</Badge>}
          {/*             {billingPeriod==='yearly' &&
              <Badge className='m-2 mt-0'>{t('pricing.saving1')+ formatPrice((price/discount[element])-price, locale, currency) + t('pricing.saving2')}</Badge>} */}
              {/* <Badge className='m-2 mt-0'>{t('pricing.days_free')}</Badge> */}
          </div>
          <div className='text-center'>
            <div className='fs-5 text-start plan-text'>{t('pricing.'+element+'_title')}</div>
            <div className='text-center discount-text'>-50<sup>%</sup></div>
            <div  className='text-start pb-2 d-flex align-items-center'>
              <div className='text-gray me-3'> <del>{formatPrice(price*2, locale, currency)}</del></div>
              <div className='fs-2 fw-bold'> {formatPrice(price, locale, currency)}</div>
            </div>
            <button id='pricing_subscribe_button' onClick={() => buttonClicked(pricingData[element].link[billingPeriod], true)}>{t('pricing.subscribe')}</button>
            <div style={{'fontSize':'14px'}} className='mt-3 text-start'>
                <div className='mt-1'><div>{t('pricing.thisIncludes')}</div> </div>
                <div className='d-flex mt-1'><CheckSimbol color ="#f0c136"/> 
                  <div className='ms-3'>
                    {t('pricing.'+element+'_row1')}
                    <a href='/pricing#indexPricingTable'>
                    {t('pricing.row1_more_info')}
                    </a>
                  </div>
                </div>
                <div className='d-flex mt-1'><CheckSimbol color ="#f0c136"/> <div className='ms-3'>{t('pricing.'+element+'_row2')}</div> </div>
                <div className='d-flex mt-1'>
                  <CheckSimbol color ="#f0c136"/>
                  <div className='ms-3'>
                    {t('pricing.'+ element+ '_row3')}
                      <div> {'⦁ ' + t('pricing.'+ element+ '_row3_1')}</div>
                      <div> {'⦁ ' + t('pricing.'+ element+ '_row3_2')}</div>
                      <div> {'⦁ ' + t('pricing.'+ element+ '_row3_3')}</div>
                      <div> {'⦁ ' + t('pricing.'+ element+ '_row3_4')}</div>
                      <div> {'⦁ ' + t('pricing.'+ element+ '_row3_5')}</div>
                  </div>
                </div>
                <div className='d-flex mt-1'><CheckSimbol color ="#f0c136"/> <div className='ms-3'>{t('pricing.'+element+'_row4')}</div> </div>
                <div className='d-flex mt-1'><CheckSimbol color ="#f0c136"/> <div className='ms-3'>{t('pricing.'+element+'_row5')}</div> </div>
                <div className='d-flex mt-1'><CheckSimbol color ="#f0c136"/> <div className='ms-3'>{t('pricing.'+element+'_row6')}</div> </div>
            </div>
          </div>
        </Card>
      </Col>
    )
  }
// check simbol
const CheckSimbol = ({color})=>{
  return(
    <div>
    <svg focusable="false" fill={color} color ="#1a1a1a"fill-opacity="0.8" height="12px" viewBox="0 0 16 16" width="12px"><path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fill-rule="evenodd"></path></svg>
    </div>
  )
}
return (
  <Container>
    <SignInModal show ={show} onHide={handleClose} url2navigate={modalUrl} send2stripe={send2stripe}></SignInModal>
    <Row className='pt-5  navigation-wrapper'>
      {/*           <div className='text-center'>
            <ButtonGroup size='lg' >
              <ToggleButton
                key={1}
                id={1}
                type="radio"
                variant={billingPeriod === 'monthly'?'primary':'light'}
                className={billingPeriod === 'monthly'?'':'billing-not-selected'}
                name="radio"
                value='monthly'
                checked={billingPeriod === 'monthly'}
                onChange={(e) => setBillingPeriod(e.currentTarget.value)}
              >
                {t('pricing.button_monthly')}
              </ToggleButton>
              <ToggleButton
                key={2}
                id={2}
                type="radio"
                variant={billingPeriod === 'yearly'?'primary':'light'}
                className={billingPeriod === 'yearly'?'':'billing-not-selected'}
                name="radio"
                value='yearly'
                checked={billingPeriod === 'yearly'}
                onChange={(e) => setBillingPeriod(e.currentTarget.value)}
              >
                {t('pricing.button_yearly')}
              </ToggleButton>
            </ButtonGroup>
          </div> */}
      <div ref={sliderRef} className="keen-slider ">
        <Col xs='12' lg='4' className='keen-slider__slide number-slide1 p-1 p-lg-3' >
          
          <Card className=' me-0 border-2 p-4 rounded-4  plan h-100 '>
            <div style={{height:'55px'}}></div>
            <div className='text-center'>
            <div className='fs-5 text-start plan-text'>{t('pricing.free_title')}</div>
            <div style={{height:'90px'}} className='fs-2 text-start pt-4 fw-bold'> {t('pricing.itsFree')}</div>
            <button onClick={()=>buttonClicked('/todaysignals', false)} id ={"pricing_subscribe_button_free"} >{t('pricing.free_button')}</button>
            <div style={{'fontSize':'14px'}} className='mt-3 text-start'>
                <div className='mt-1'><div>{t('pricing.thisIncludes')}</div> </div>
                <div className='d-flex mt-1'><CheckSimbol color ="#f0c136"/> 
                  <div className='ms-3'>
                    {t('pricing.row1')}
                    <a href='/pricing#indexPricingTable'>
                    {t('pricing.row1_more_info')}
                    </a>
                  </div>
                </div>
                <div className='d-flex mt-1'><CheckSimbol color ="#f0c136"/> <div className='ms-3'>{t('pricing.row2')}</div> </div>
                <div className='d-flex mt-1'>
                  <CheckSimbol color ="#f0c136" />
                  <div className='ms-3'>
                    {t('pricing.row3')}
                      <div> {'⦁ ' + t('pricing.row3_1')}</div>
                      <div> {'⦁ ' + t('pricing.row3_2')}</div>
                      <div> {'⦁ ' + t('pricing.row3_3')}</div>
                      <div> {'⦁ ' + t('pricing.row3_4')}</div>
                      <div><del> {'⦁ ' + t('pricing.pro_row3_5')}</del></div>
                  </div>
                </div>
                <div className='d-flex mt-1'><CheckSimbol color ="#f0c136"/> <div className='ms-3'>{t('pricing.row4')}</div> </div>
                <div className='d-flex mt-1'><CheckSimbol color ="grey"/> <div className='ms-3'><del>{t('pricing.pro_row5')}</del></div> </div>
                <div className='d-flex mt-1'><CheckSimbol color ="grey"/> <div className='ms-3'><del>{t('pricing.pro_row6')}</del></div> </div>
            </div>
            </div>
          </Card>
        </Col>
        {Object.keys(pricingData).map((e)=>PricingElement(e))}
      </div>
      {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow

              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
    </Row>

  </Container>
);
};

export default PricingTables;