import React from 'react';
import './Landing.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const HowItWorks = ({ option, dark }) => {
  // Hook for translation
  const { t } = useTranslation();

  // Dynamically get the bullets based on the `option`
  const bullets = [];
  for (let i = 1; i <= 5; i++) {
    const bullet = t(`landing.how_it_works.${option}.bullet_${i}`);
    if (bullet && bullet !== `landing.how_it_works.${option}.bullet_${i}`) {
      bullets.push(bullet);
    }
  }

  return (
    <div className={dark ? 'black-background text-white' : 'white-background text-black'}>
      <Container className="padding-section">
        <Row className="align-items-center text-center">
          <Col xs={12} lg={6} className="mt-5 px-lg-3 order-2 order-lg-1">
            <video muted autoPlay loop className="img-shadows video">
              <source
                src={
                  "https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/videos/TodaySignals_comp.mp4#t=0"
                }
                type="video/mp4"
              />
            </video>
          </Col>
          <Col xs={12} lg={6} className="mt-lg-0 order-1 order-lg-2">
            <div className="title align-items-center text-center ">
              {t(`landing.how_it_works.${option}.title`)}
            </div>
            <div className="text mt-5 mb-4 px-lg-2 text-center text-lg-start">
              {t(`landing.how_it_works.${option}.text`)}
            </div>

            <Row className="mt-3 mt-lg-2 text-start text px-lg-4">
              <Col className="col-auto mx-auto">
                <ul>
                  {bullets.map((bullet, index) => (
                    <li
                      key={index}
                      className={`list-bullet mt-1 mt-lg-3 ${
                        dark ? 'text-white' : 'text-black'
                      }`}
                    >
                      {bullet}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HowItWorks;
