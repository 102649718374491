
import { FormSelect, Button, Navbar, Nav, Container,Offcanvas, Row,Col } from 'react-bootstrap';
import i18n from '../../language/i18';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { setSettings } from '../../redux/settingsReducer';
import '../header/Header.css'
import SignInModal from './SingInModal';
import JoinButton from './JoinButton';
import { useLocation, useNavigate } from 'react-router-dom';
const LandingHeader = ({joinButtonTextOption}) => {
    // code to get settings values from redux
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settings.settings);
    const setSettings1 = (res)=>{dispatch(setSettings(res))}
    // so the header can be used in pages where the offcanvas is not needed
    const [language, setLanguage] = useState(settings.language);

    // hook for translation
    const { i18n, t } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language);
        setSettings1({...settings, language: language})
    }, [language]);
    // google analytics
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    // modal for login Button
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // to detect if we have to scroll to a section
    const location = useLocation();
    useEffect(() => {
        // Scroll to the element if the URL contains a hash
        if (location.hash) {
            const elementId = location.hash.substring(1); // Remove the '#' to get the id
            const targetElement = document.getElementById(elementId);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    // for navigation
    const navigate = useNavigate();
    // ofcanvas for landing
    const sections = [
        { id: 'pricing', label: 'pricing' },
        { id: 'faq', label: 'faq' },
        { id: 'social_proof', label: 'why_us' },
        { id: 'how_it_works', label: 'how_it_works' }
      ];

    const [openOffcanvas, setOpenOffcanvas] = useState(false);
    const  LandingOffcanvas= ({openOffcanvas, handleOffcanvas})=>{
        return(
        <Offcanvas show={openOffcanvas} onHide={handleOffcanvas} {...{placement:'end'}}>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='text-end d-flex flex-column'>
            <div>
                {sections.map((section)=>(
                <div
                    onClick={() => {
                        setOpenOffcanvas(false)
                        navigate(`/#${section.id}`)
                    }}
                    className='title-offcanvas text-end mt-3'
                    style={{cursor:'pointer'}}
                >
                  {t('landing.offcanvas.'+section.label)}
                </div>
                ))}
                <div
                    className='title-offcanvas text-end mt-3'
                    style={{cursor:'pointer'}}
                    onClick={()=>(navigate('/aboutus'))}
                >
                  {t('landing.offcanvas.about_us')}
                </div>
            </div>
            <Row className='mt-auto pb-5 text-center'>
                <Col>
                <Button id="login_button" className='buttonHoverEffect joinButton joinButton-black w-100' onClick={()=>{setShow(true)}}>
                    {t('landing.login')}
                </Button>
                </Col>
                {joinButtonTextOption === undefined ?
                null
                : 
                <Col>
                    <JoinButton addClass={'joinButton-pink text-black w-100'} joinButtonTextOption={joinButtonTextOption}></JoinButton>
                </Col>}
            </Row>
        </Offcanvas.Body>
        </Offcanvas>
        )
    }
return (
<>
<LandingOffcanvas openOffcanvas={openOffcanvas} handleOffcanvas={() => setOpenOffcanvas(false)}></LandingOffcanvas>
<Navbar className="bg-dark pb-1 pt-1" sticky='top'>
    <Container>
    <Nav.Link href='/' className= "d-flex">
        <img className= "mx-1" height="45" src={'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/white_logo.svg'} loading="lazy"></img>
    </Nav.Link>
        <div  className='d-flex me-2 col justify-content-end' >
            {sections.map((section)=>(
                <a  href={`/#${section.id}`}
                    onClick={() => navigate(`/#${section.id}`)}
                    className='d-none d-lg-block p-2 pe-4 text-light text-decoration-none'
                >
                  {t('landing.offcanvas.'+section.label)}
                </a>
            ))}

            <a className='d-none d-lg-block p-2 pe-4 text-light text-decoration-none' href="/aboutus">{t('header.aboutus')}</a>

            <FormSelect bsPrefix='language-select' onChange={(e)=>{setLanguage(e.target.value)}} size="sm" className="bg-dark text-light me-2 border-0" defaultValue={language}>
                <option value="en">En</option>
                <option value="es">Es</option>
            </FormSelect>

            <Button id="login_button" className='ms-3 logInButton' onClick={()=>{setShow(true)}}>
                {t('landing.login')}
            </Button>

            <SignInModal show ={show} onHide={handleClose} url2navigate='/todaysignals'></SignInModal>

            <svg id="menu_button" className="d-block d-lg-none ms-3 mt-1" onClick={()=>(setOpenOffcanvas(true))} xmlns="http://www.w3.org/2000/svg" type="button" width="30px" height="30px" fill="white" viewBox="0 0 16 16"  >
            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
        </div>
    
    </Container>
</Navbar>
</>

  );

};

export default LandingHeader;
