import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { Modal, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { decodeJwt } from 'jose';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../redux/userReducer';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';
import { host } from '../../utils/variablesProdTest';
import { useEffect, useState } from 'react';

function SignInModal({ show, onHide, url2navigate, send2stripe }) {
    // hook for translation
    const { i18n, t } = useTranslation();
    // functions to handle user
    const dispatch = useDispatch();
    // function to navigate
    const navigate = useNavigate();
    const currentLocation = useLocation(); // Renamed to avoid conflict with global location
    // state to track user update completion
    const [userUpdateComplete, setUserUpdateComplete] = useState(false);
    // selector to get user from redux store
    const user = useSelector((state) => state.user);
    // we get the landing the user had
    const landingArray = JSON.parse(localStorage.getItem('landingArray'));

    // function to save user in dB
    const updateUser = (user) => {
        axios.post(host + "updateUser.php", {
            email: user.email,
            name: user.name,
            last_name: user.last_name,
            info: user.info,
            sub: user.sub,
            settings: user.settings
        }).then(data => {
            const updatedUser = { ...user, ...data.data }; //we append the received data to user and return this as the user
            dispatch(setUser(updatedUser));
            setUserUpdateComplete(true);
        }).catch((error) => {
            // Error handling
            if (error.response) {
                console.error(error.response.data);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        })
    };

    // functions to handle login
    const onSuccess = (res) => {
        // Decode the JWT
        const decodedToken = decodeJwt(res.credential);
        try {
            updateUser({
                email: decodedToken.email,
                name: decodedToken.given_name,
                last_name: decodedToken.family_name,
                sub: decodedToken.sub,
                info: decodedToken,
                settings: {
                    locale: decodedToken.locale,
                    landingArray:landingArray
                },
                stats: {
                    lastConnection: JSON.stringify(new Date()),
                    
                },
            });
        } catch (error) {
            // Handle decoding errors
            console.error('Error decoding JWT:', error.message);
        }
    };

    const onFailure = (res) => {
        console.log("Login failed", res);
    };
    // useEffect to navigate when user is updated
    useEffect(() => {
        if (userUpdateComplete && user) {
            if(url2navigate === null){
                navigate(currentLocation.pathname); // if the url to navigate is null we navigate to the current url
                return;
            }
            if(send2stripe === true){
                const encodedEmail = encodeURIComponent(user.user.email);
                window.location.href =url2navigate +'?prefilled_email='+encodedEmail+'&client_reference_id='+user.user.sub; // if send2stripe is true this has been activated at the landing pricing, and we have recived
                // an incomplete url with this format {pricingData[element].link[billingPeriod]+'?prefilled_email='+encodedEmail+'&client_reference_id='+user.sub but it lacks 
                // the user id because the user wasn't log in till now so we add the email and user id to the url and send the user there
                return;
            }
            navigate(url2navigate)
        }
    }, [userUpdateComplete, user]);
        // useEffect to navigate without sign-in if the user is already signed in
        useEffect(() => {
            if ((show===true) && (user.user!=null)) {
                // Only run this when the modal is shown
                if (url2navigate === null) {
                    navigate(currentLocation.pathname); // if the URL to navigate is null, navigate to the current URL
                    return;
                }
                if (send2stripe === true) {
                    const encodedEmail = encodeURIComponent(user.user.email);
                    window.location.href =
                        url2navigate +
                        '?prefilled_email=' +
                        encodedEmail +
                        '&client_reference_id=' +
                        user.user.sub; // Complete the Stripe redirect
                    return;
                }
                navigate(url2navigate);
            }
        }, [show, user]); // Depend on `show` and `user` to only trigger when modal is shown and user is set
    
        return (
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                keyboard={false}
            >
                {onHide && <Modal.Header closeButton></Modal.Header>}
                <Modal.Body className="container">
                    <Row>
                        <Col className="d-flex col justify-content-center mt-5">
                            <img
                                className="mx-1"
                                height="100"
                                src={'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/black_logo.svg'}
                                loading="lazy"
                            ></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex col justify-content-center text-center fs-5 mt-2">
                            {t('singInModal.singin')}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex col justify-content-center mt-5 mb-5">
                            <GoogleLogin
                                style={{ backgroundColor: 'var(--bs-body-color)' }}
                                onSuccess={onSuccess}
                                onError={onFailure}
                                useOneTap={false}
                            >
                                SignIn
                            </GoogleLogin>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col text-center small">
                            {t('singInModal.policy1')}
                            <Link to={'/privacy-policy'}>
                                {t('singInModal.privacy_policy')}
                            </Link>
                            {t('singInModal.policy2')}
                            <Link to={'/terms-and-conditions'}>
                                {t('singInModal.terms_conditions')}
                            </Link>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
    
    export default SignInModal;