import { testmode } from './variablesProdTest';
 //data for pricing
 export const pricingData = 
 {
   premium:{
     link: {
       monthly: 
         testmode===true 
         ? 'https://buy.stripe.com/test_aEU8Ai3169vD01yaEK'
         : 'https://buy.stripe.com/4gw6qf55d22n1Q44gk',
       yearly:
         testmode===true 
         ? 'https://buy.stripe.com/test_9AQ7wedFKePXg0weV4'
         : 'https://buy.stripe.com/fZe4i755d9uPgKY28a',
     },
     priceId: {
       monthly: 
         testmode===true 
         ? 'price_1PUVrmB8UNTZlgmoHBglv2Xa'
         : 'price_1PWDyAB8UNTZlgmo5yQYoGeX',
       yearly:
         testmode===true 
         ? 'price_1PUnP5B8UNTZlgmopmLZ1Kjv'
         : 'price_1PWDxWB8UNTZlgmodsYE2eGo',
     },
     currencies: 
     {  "ARS": { "monthly": 13999.00, "yearly": 134399.00 },
     "AUD": { "monthly": 44.99, "yearly": 431.99 },
     "BRL": { "monthly": 89.99, "yearly": 863.99 },
     "CAD": { "monthly": 39.99, "yearly": 383.90 },
     "CHF": { "monthly": 34.99, "yearly": 335.00 },
     "CLP": { "monthly": 14999.00, "yearly": 143999.00 },
     "CNY": { "monthly": 119.00, "yearly": 1145.00 },
     "EUR": { "monthly": 29.99, "yearly": 287.90 },
     "GBP": { "monthly": 24.99, "yearly": 239.99 },
     "HKD": { "monthly": 189.00, "yearly": 1815.00 },
     "INR": { "monthly": 1300.00, "yearly": 12480.00 },
     "JPY": { "monthly": 3500.00, "yearly": 33599.00 },
     "KRW": { "monthly": 32000.00, "yearly": 307200.00 },
     "MXN": { "monthly": 299.99, "yearly": 2879.00 },
     "NOK": { "monthly": 339.00, "yearly": 3255.00 },
     "PLN": { "monthly": 109.00, "yearly": 1049.00 },
     "USD": { "monthly": 29.99, "yearly": 287.99 },
     "ZAR": { "monthly": 269.00, "yearly": 2582.00 }}
   },

   pro:{
     link: {
       monthly: 
         testmode===true 
         ? 'https://buy.stripe.com/test_28oaIqaty0Z701yeV2'
         : 'https://buy.stripe.com/28o6qfapx8qLamAcMP',
       yearly:
         testmode===true 
         ? 'https://buy.stripe.com/test_8wM4k2dFK5fn8y45kv'
         : 'https://buy.stripe.com/dR6aGv7dl6iD2U8145',
     },
     priceId: {
       monthly: 
         testmode===true 
         ? 'price_1PUnb0B8UNTZlgmoAoinFDiz'
         : 'price_1PWDxYB8UNTZlgmo870vVK7R',
       yearly:
         testmode===true 
         ? 'price_1PUnb0B8UNTZlgmoJ5KPDDM2'
         : 'price_1PWDxTB8UNTZlgmon43VkUKC',
     },
     currencies: {
       "ARS": { "monthly": 20000.00, "yearly": 168000.00 },
       "AUD": { "monthly": 42.99, "yearly": 361.00 },
       "BRL": { "monthly": 149.99, "yearly": 1259.99 },
       "CAD": { "monthly": 64.99, "yearly": 545.99 },
       "CHF": { "monthly": 49.99, "yearly": 419.99 },
       "CLP": { "monthly": 22999.00, "yearly": 193191.00 },
       "CNY": { "monthly": 169.00, "yearly": 1419.00 },
       "EUR": { "monthly": 49.99, "yearly": 419.99 },
       "GBP": { "monthly": 39.99, "yearly": 335.99 },
       "HKD": { "monthly": 309.00, "yearly": 2595.00 },
       "INR": { "monthly": 2200.00, "yearly": 18480.00 },
       "JPY": { "monthly": 5899.00, "yearly": 49550.00 },
       "KRW": { "monthly": 52000.00, "yearly": 436800.00 },
       "MXN": { "monthly": 469.99, "yearly": 3945.00 },
       "NOK": { "monthly": 559.00, "yearly": 4695.60 },
       "PLN": { "monthly": 199.00, "yearly": 1670.00 },
       "USD": { "monthly": 49.99, "yearly": 419.99 },
       "ZAR": { "monthly": 415.00, "yearly": 3486.00 }
     }
   }

 }; 

 //detecting the currency
 export const countryToCurrencyMap = {
    'US': 'USD',
    'EU': 'EUR',
    'GB': 'GBP',
    'MX': 'MXN',
    'AR': 'ARS',
    'BR': 'BRL',
    'JP': 'JPY',
    'KR': 'KRW',
    'CA': 'CAD',
    'NO': 'NOK',
    'CH': 'CHF',
    'AU': 'AUD',
    'CL': 'CLP',
    'PL': 'PLN',
    'HK': 'HKD',
    'CN': 'CNY',
    'ZA': 'ZAR',
    'IN': 'INR',
    'AT': 'EUR',
    'BE': 'EUR',
    'CY': 'EUR',
    'EE': 'EUR',
    'FI': 'EUR',
    'FR': 'EUR',
    'DE': 'EUR',
    'GR': 'EUR',
    'IE': 'EUR',
    'IT': 'EUR',
    'LV': 'EUR',
    'LT': 'EUR',
    'LU': 'EUR',
    'MT': 'EUR',
    'NL': 'EUR',
    'PT': 'EUR',
    'SK': 'EUR',
    'SI': 'EUR',
    'ES': 'EUR'
  };