import { useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SignInModal from './SingInModal';
import { useSelector } from 'react-redux';
import './Landing.css'
function JoinButton({addClass, joinButtonTextOption}){
    // hook for translation
    const { i18n, t } = useTranslation();
    const [show, setShow] = useState(false);
    // functions to open moda
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (user===null){
            setShow(true);
        }
        else{
            navigate('/todaysignals')
        }
        }
    //function to navigate
    const navigate = useNavigate();
    //const handleShow2 = () => navigate('/todaysignals');
    // code for getting the user from redux
    const user = useSelector((state) => state.user.user);
    return(
    <div id="signIn_button">
        <button id="signIn_button" onClick={handleShow} className={"buttonHoverEffect joinButton "+addClass}>{t('landing.joinButton.'+joinButtonTextOption)}</button>    
        <SignInModal show={show} onHide={handleClose} url2navigate='/pricing'></SignInModal>
    </div>
    )
}

export default JoinButton;