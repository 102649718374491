import { useState} from "react";
import Header from "../components/header/Header";
import Footer from '../components/Footer';
import WikiOffcanvas from '../components/wiki_components/WikiOffcanvas';
import Container from 'react-bootstrap/Container';
import { Outlet } from "react-router-dom";
import {initTheme} from '../utils/theme'
import { useSelector } from 'react-redux';
import SignInModal from '../components/landing/SingInModal';
import SubscribeOnScreen from "../components/subscribeOnScreen/SubscribeOnScreen";

function Wiki() {
  // code for initializing the theme
  initTheme()
  // code for getting the user from redux
  const user = useSelector((state) => state.user.user);
  // this manages the open and close of the offcanvas
  const [open, setOpen] = useState(false);
  const handleShow = () =>   {
    setOpen(!open);
  }
  return (
  <div>
  
  <Header handleShow={handleShow}></Header>
  {user!=null && user.subscription==='free' && <SubscribeOnScreen></SubscribeOnScreen>}
{/*   {user===null && 
  <SignInModal show={true} onHide={null} url2navigate={null}></SignInModal>
  } */}
  <WikiOffcanvas open={open} handleShow={handleShow}></WikiOffcanvas>
  <Container fluid="lg" className="mt-5 mb-5">
    <Outlet/>
  </Container>
  <Footer></Footer>
</div>
  );
}

export default Wiki;
