import React from 'react';
import './Landing.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Solution = ({ option, dark }) => {
  // hook for translation
  const { t } = useTranslation();

  return (
    <div className={dark ? 'black-background text-white' : 'white-background text-black'}>
      <Container className="padding-section">
        <Row className="text-center">
          <Col className="title">
            {t('landing.solution.' + option + '.title_1')}
            <span className="title-attention">
              {t('landing.solution.' + option + '.title_enfasis')}
            </span>
            {t('landing.solution.' + option + '.title_2')}
          </Col>
        </Row>
        <Row className="mt-4 mt-lg-5 text-center text">
          <Col>{t('landing.solution.' + option + '.subtitle')}</Col>
        </Row>
        <Row className="mt-4 mt-lg-5 text-start text">
          <Col className="col-auto mx-auto">
            <ul>
              <li
                className={`list-bullet ${dark ? 'text-white' : 'text-black'}`}
              >
                {t('landing.solution.' + option + '.bullet_1')}
              </li>
              <li
                className={`list-bullet mt-1 mt-lg-3 ${dark ? 'text-white' : 'text-black'}`}
              >
                {t('landing.solution.' + option + '.bullet_2')}
              </li>
              <li
                className={`list-bullet mt-1 mt-lg-3 ${dark ? 'text-white' : 'text-black'}`}
              >
                {t('landing.solution.' + option + '.bullet_3')}
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-4 mt-lg-5 text-center text">
          <Col>
            <video muted autoPlay loop className="img-shadows video">
              <source
                src={
                  "https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/videos/Backtesting_comp.mp4#t=0"
                }
                type="video/mp4"
              />
            </video>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Solution;
