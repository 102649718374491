import React from 'react';
import './Landing.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Critique = ({ option }) => {
  // Hook for translation
  const { t } = useTranslation();

  // Dynamically fetch titles and texts
  const critiques = [];
  for (let i = 1; i <= 5; i++) {
    const title = t(`landing.critique.${option}.title_${i}`);
    const text = t(`landing.critique.${option}.text_${i}`);
    if (
      title &&
      text &&
      title !== `landing.critique.${option}.title_${i}` &&
      text !== `landing.critique.${option}.text_${i}`
    ) {
      critiques.push({ title, text });
    }
  }

  return (
    <div className="black-background text-white">
      <Container className="padding-section">
        {critiques.map((critique, index) => (
          <Row
            key={index}
            className={`align-items-center mt-5 ${
              index % 2 === 0 ? 'text-start' : 'text-end'
            }`}
          >
            <Col className={`col-12 col-md-8 ${index % 2 !== 0 ? 'ms-auto' : ''}`}>
              <div
                className="title"
                style={{
                  width: '100%',
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                {critique.title}
              </div>
              <div className="text mt-4 mb-4">{critique.text}</div>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};

export default Critique;
