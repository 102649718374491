import React from 'react';
import './Landing.css';
import JoinButton from './JoinButton';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LastCall2Action = ({option, joinButtonTextOption}) => {
  // hook for translation
  const { i18n, t } = useTranslation();
  return (
  <div className='lastCall2Action-background'>
    <Container className=' padding-section text-center'>
      <div className='title-big'>
        {t('landing.last_call_to_action.'+option+'.title')}
      </div>
      <div className='subtitle mt-4 mb-4'>
        {t('landing.last_call_to_action.'+option+'.subtitle')}
      </div>
      <JoinButton addClass={'joinButton-black'} joinButtonTextOption={joinButtonTextOption}></JoinButton>
    </Container>
  </div>
    );
  };

export default LastCall2Action;