export const landingEs = {
    // Estas son las secciones sin opciones
    offcanvas:{
        pricing:'Precios',
        faq:'FAQ',
        why_us:'¿Por qué nosotros?',
        how_it_works:'¿Cómo funciona?',
        about_us:'Sobre nosotros'
    },
    alert_bar_discount:{
        text:'Oferta limitada por Navidad!',
        link:'↓↓↓'
    },
    faq:{
        title:'Preguntas Frecuentes',
        subtitle:'¿Tienes otra pregunta? Contáctanos por ',
        subtitle_link:'correo electrónico.',
        0:{
            question:'¿Qué es AtomicStonks?',
            answer:'AtomicStonks es una plataforma de análisis del mercado de valores diseñada para optimizar tu proceso de inversión. \n\nProporciona un análisis rápido del mercado con 5 estrategias diferentes para más de 1700 empresas de 30 de los principales índices del mundo, incluyendo algunos mercados emergentes para proporcionarte datos relevantes y oportunidades para diversificar tu riesgo.'
        },
        1:{
            question:'¿En qué se diferencia AtomicStonks de los métodos tradicionales de inversión?',
            answer:'A diferencia de los métodos tradicionales, AtomicStonks automatiza muchas tareas que consumen tiempo, como analizar gráficos y filtrar acciones. También te permite probar múltiples estrategias de inversión simultáneamente, lo que puede mejorar significativamente tus decisiones de inversión. \n\nAdemás, nuestra plataforma proporciona acceso a una amplia gama de acciones, incluidos mercados emergentes, que aunque podrían estar en canales tradicionales, muchas personas ni siquiera los consideran en un principio.'
        },
        2:{
            question:'¿Es AtomicStonks adecuado para inversores principiantes y experimentados?',
            answer:'¡Absolutamente! Nuestra plataforma está diseñada para ser fácil de usar para inversores de todos los niveles. Ya seas un inversor experimentado o estés comenzando, AtomicStonks puede ayudarte a tomar decisiones de inversión informadas y alcanzar tus metas financieras.\n\n Recomendamos obviamente revisar algunos de los libros que mencionamos en la bibliografía en el wiki, para tener nociones básicas de análisis técnico. No es absolutamente obligatorio, pero probablemente mejorará tus rendimientos sustancialmente.' 
        },
        3:{
            question:'¿Qué tipo de datos y análisis proporciona AtomicStonks?',
            answer:'Nuestra plataforma ofrece una amplia gama de datos y análisis, incluidos datos de mercado, gráficos de precios históricos, análisis fundamental, indicadores técnicos y más. Puedes usar esta información para identificar tendencias, evaluar riesgos y tomar decisiones de inversión informadas.' 
        },
        4:{
            question:'¿Con qué frecuencia se actualizan los datos en AtomicStonks?',
            answer:'Cada vez que un mercado cierra, ejecutamos nuestros algoritmos nuevamente para verificar si se proporcionan nuevas señales.' 
        },
        5:{
            question:'¿Por qué estas estrategias y no otras?',
            answer:'No existe una respuesta única para esta pregunta, ya que las mejores estrategias de trading variarán según tu tolerancia al riesgo, metas de inversión y condiciones del mercado. Nos enfocamos en estas estrategias porque están bien establecidas y tienen un historial comprobado de éxito. Hemos dejado una larga lista de bibliografía en el wiki para consultar diferentes libros que hablan sobre estas estrategias. ¡Estamos considerando traer más opciones a la mesa, así que nos encantaría recibir comentarios y recomendaciones!' 
        },
        6:{
            question:'¿Por qué solo 7, 30, 90 y 180 días de retroceso en el BackTesting?',
            answer:'Hay varias razones por las que AtomicStonks limita el retroceso del backtesting a 7, 30, 90 y 180 días. La principal es la relevancia de los datos. Somos muy estrictos con esto ya que es uno de nuestros valores fundamentales. Podríamos tener una lista de cómo ha funcionado cada señal diariamente durante los últimos 5 años sin mucho problema, sin embargo, la digestión de esa información sería atroz para ti. Cuanta más información hay, más difícil es para cualquiera entender lo que está sucediendo. La otra razón principal es que consideramos que estas estrategias suelen ser operaciones de corto y medio plazo. No hay razón para retroceder mucho más en el tiempo para sentir el rendimiento o la tendencia de los mercados. Es por eso que la sección con estos marcos de tiempo tiene más sentido para nosotros. Sin embargo, si no estás de acuerdo, por favor háznoslo saber, nos esforzamos por entender las necesidades y opiniones de los clientes.' 
        },
        7:{
            question:'¿Mis datos están seguros en AtomicStonks?',
            answer:'Sí, nos tomamos muy en serio la seguridad de los datos. Por eso utilizamos Google Sign-In para minimizar el manejo de información personal. También empleamos medidas de seguridad avanzadas para proteger la poca información personal con la que podamos necesitar trabajar.' 
        },
    },
    social_proof:{
        title:'¿Qué dicen nuestros clientes de nosotros?',
        0:{
            name:'Victor G',
            quote: '“AtomicStonks me ha ayudado a ahorrar tiempo haciendo mis comprobaciones, lo uso a diario.”',
            user_type:'Usuario Pro'
        },
        1:{
            name:'David G',
            quote:'“El feed diario de señales me ayudó a encontrar oportunidades en mercados que ni siquiera estaba considerando.”',
            user_type:'Usuario Premium'
        },
        2:{
            name:'Sophie P',
            quote: '“Quería empezar a invertir y esto me ha ayudado mucho. Sentía que era demasiado complicado y ahora solo toma unos minutos.”',
            user_type:'Usuario Pro'
        },
        3:{
            name:'Andreu S',
            quote:'“Herramienta súper útil para mantenerse al tanto del mercado sin ahogarse en datos.“',
            user_type:'Usuario Premium'
        },
        4:{
            name:'Edu A',
            quote: '“AtomicStonks es un cambio de juego para cualquiera en el mundo de la inversión, especialmente para quienes quieren ser eficientes pero acertados al seleccionar.”',
            user_type:'Usuario Pro'
        },
        5:{
            name:'Alba B',
            quote:'“¡AtomicStonks es increíble! Las señales diarias detalladas podrían ser exactamente lo que muchos inversores necesitan para mantenerse por delante.”',
            user_type:'Usuario Premium'
        }
    },
    comparison:{
        title:'¿Cuántas horas pasas viendo gráficos?',
        title_without:'Sin AtomicStonks',
        text_without_1:'Horas analizando gráficos',
        text_without_2:'Revisas una estrategia a la vez',
        text_without_3:'Analizas las mismas empresas',
        text_without_4:'Nunca descubres nuevos mercados',
        text_without_5:'Si no encuentras una inversión, repites todo al día siguiente',
        title_with:'Con AtomicStonks',
        text_with_1:'Minutos para analizar todo el mercado',
        text_with_2:'Revisas 5 estrategias diferentes al mismo tiempo',
        text_with_3:'Analizas empresas que quizás nunca habías oído',
        text_with_4:'Descubres nuevos mercados emergentes',
        text_with_5:'Si no encuentras una inversión, vuelve al día siguiente.',
    },
    try_it:{
        title:'¡Pruébalo tú mismo!',
        text_up_signal:'En este ejemplo, tenemos una señal de compra de la estrategia Bollinger + RSI \nindicando que podría ser una buena idea comprar Disney.',
        text_down_signal:'¡Usa los botones para hacer un análisis rápido de la empresa!',
    },    
    pricing:{
        title:'Ahorra horas. Explora nuevos mercados.',
        subtitle:"¡No te pierdas la oferta de Navidad! 50% en la subscripción para siempre"
    },
    // desde aquí hacia abajo están las secciones que tienen opciones
    hero:{
        0:{
            title:'Señales automatizadas de oportunidades de inversión.',
            subtitle:'Estate por delante del mercado sin pasar días analizando gráficos',
            underButtonText:'No te pierdas la oferta, ya no queda mucho!',
            socialProofText:'+10 000 inversores ya están ahorrando tiempo'
        },
        1:{
            title:'Señales de inversión automatizadas',
            subtitle:'AtomicStonks te ayuda a tomar mejores decisiones de inversión. Nuestro algoritmo avanzado analiza vastas cantidades de datos de mercado de más de 1700 empresas, para identificar tendencias y encontrar grandes oportunidades de inversión.',
            underButtonText:'¿Quieres probarlo gratis?',
            socialProofText:'+10 000 inversores ya están ahorrando tiempo'
        },
        2:{
            title:'Las acciones más interesantes de hoy',
            subtitle:'Cada día, AtomicStonks analiza las cotizaciones de más de 1.700 empresas y elabora una lista de las más importantes.',
            underButtonText:'¿Quieres probarlo gratis?',
            socialProofText:'+10 000 inversores ya están ahorrando tiempo'
        },
        3:{
            title:'Aquí está la lista de las acciones más interesantes de hoy',
            subtitle:'Cada día, AtomicStonks analiza las cotizaciones de más de 1.700 empresas y elabora una lista de las más importantes.',
            underButtonText:'¿Quieres probarlo gratis?',
            socialProofText:'+10 000 inversores ya están ahorrando tiempo'
        },
    },
    solution:{
        0:{
            title_1:'Tu herramienta de análisis ',
            title_enfasis:'automático',
            title_2:' del mercado de valores mundial',
            subtitle:'AtomicStonks monitorea y analiza más de 1700 empresas de los principales índices de todo el mundo para crear un feed diario de señales de inversiones potenciales.',
            bullet_1:'30 índices de las economías mundiales más importantes.',
            bullet_2:'5 estrategias diferentes para encontrar tu nueva inversión.',
            bullet_3:'Datos fundamentales para profundizar el análisis.',
        },
        1:{
            title_1:'Invertir puede ser ',
            title_enfasis:' abrumador ',
            title_2:'...',
            subtitle: 'Pasas muchas horas revisando gráficos, fundamentales y encontrando la estrategia perfecta. \n AtomicStonks te proporciona un feed diario automatizado de señales para encontrar la oportunidad de inversión perfecta para ti.',
            bullet_1:'30 índices de las economías mundiales más importantes.',
            bullet_2:'5 estrategias diferentes para encontrar tu nueva inversión.',
            bullet_3:'Datos fundamentales para profundizar el análisis.',
        },
        2:{
            title_1:'¿Cómo eliges dónde poner tu',
            title_enfasis:' dinero ',
            title_2:'?',
            subtitle:'AtomicStonks monitorea y analiza más de 1700 empresas de los principales índices de todo el mundo para crear un feed diario de señales de inversiones potenciales.',
            bullet_1:'30 índices de las economías globales más importantes.',
            bullet_2:'5 estrategias diferentes para encontrar tu nueva inversión.',
            bullet_3:'Datos fundamentales para profundizar el análisis.',
        },
    },
    critique:{
        0:{
            title_1:'Hay más de 50,000 empresas cotizadas en el mundo',
            text_1:'¿Sigues invirtiendo en las mismas empresas una y otra vez?\n Tal vez tu próxima oportunidad esté en otro lugar.',
            title_2:'¿Cómo eliges dónde poner tu dinero?',
            text_2:'¿Por que la empresa es famosa? Sólido.\n ¿Tu primo invirtió allí? Buena elección.\n¿Revisaste los fundamentales? Claro que sí.',
            title_3:'Lo entendemos, puede ser abrumador',
            text_3:'Por eso hicimos AtomicStonks, para ayudarte a ahorrar horas analizando y encontrar mejores oportunidades.'
        },
        1:{
            title_1:'Hay más de 50,000 empresas cotizadas en el mundo',
            text_1:' Estás intentando encontrar la inversión correcta con un gráfico, analizando una a la vez.',
            title_2:'¿Cómo eliges dónde invertir?',
            text_2:'¿Buscando tendencias? \n ¿Siguiendo el consejo de amigos o influencers?',
            title_3:'Lo entendemos, puede ser abrumador',
            text_3:'Por eso hicimos AtomicStonks, para ayudarte a ahorrar horas y encontrar mejores oportunidades!',
        },
        2:{
            title_1:'Hay más de 50,000 empresas cotizadas en el mundo',
            text_1:'¿Sigues invirtiendo en las mismas empresas una y otra vez? Tal vez tu próxima oportunidad esté en otro lugar.',
            title_2:'Lo entendemos, puede ser abrumador',
            text_2:'Por eso hicimos AtomicStonks, para ayudarte a ahorrar horas analizando y encontrar mejores oportunidades!',
        },
    },
    how_it_works:{
        0:{
            title:'Cómo funciona',
            text:'AtomicStonks hace el trabajo pesado. Te crea una lista de señales generades con diferentes estrategieas de inversión. Tan solo necesitas:',
            bullet_1:'Encontrar una señal que coincida con tu perfil de riesgo.',
            bullet_2:'Analizar por qué la estrategia generó una señal para esa acción.',
            bullet_3:'Acceder a los fundamentos de la empresa para obtener información más detallada.',
            bullet_4:'¡Decidir si es adecuada para ti!',
            bullet_5:'¡Repite!',
        },
        1:{
            title:'Cómo funciona',
            text:'Cada día encontrarás una lista con todas las señales generadas, las empresas más relevantes del día.\nAtomicStonks hace el trabajo pesado, solo necesitas elegir la mejor oportunidad de inversión para ti.',
            bullet_1:'Accede a los fundamentos de la empresa para obtener información más detallada.',
            bullet_2:'Analiza por qué la estrategia generó una señal...',
            bullet_3:'Revisa la sección de Backtesting para ver cómo se han desempeñado las señales en el pasado.',
        },
    },
    features:{
        0:{
            title_1:'Analiza el mercado en minutos',
            text_1:'Obtén un feed diario de señales de más de 1700 empresas y 30 índices',
            title_2:'Descubre nuevas oportunidades',
            text_2:'Accede a un amplio espectro de acciones, incluidas aquellas que pasan desapercibidas en el análisis de inversión tradicional.',
            title_3:'Prueba múltiples estrategias',
            text_3:'Prueba simultáneamente 5 estrategias de inversión diferentes e identifica oportunidades de alto potencial.',
        },
    },
    last_call_to_action:{
        0:{
            title:'Mejora tus decisiones en el mercado de valores.\nEmpieza a ganar.',
            subtitle:'No pierdas más tiempo analizando gráficos.'
        },
    },
    joinButton:{
        0:'¡Únete!',
        1:'Comienza ahora',
        2: '¡Descúbrelas!'
    },
    login:'Log In', 
};
